import {
  Container,
  Item,
  Card,
  Btn,
  Icon,
  DetailItem,
  Img,
  CoverImg,
  Title,
  Description,
  Price,
  Unit,
  BtnAdd,
  IconBtnAdd,
  Weight,
  UnitWeight,
  DivProgressBar,
  DiscountPrice,
  DiscountPriceLable,
  ContainerItems,
  ImgCategory,
  Category,
  ContainerCategories,
  DivFilters,
  Btnfilter,
} from "./ElementList";
import card from "../../Images/cards/product.svg";
import card_ltr from "../../Images/cards/product_ltr.svg";
import setting from "../../Images/setting.svg";
import icon from "../../Images/addIcon.svg";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import VisibilitySensor from "react-visibility-sensor";

const percentage = 66;

const List = ({
  categories,
  data,
  handleOpenModal,
  filterWithCategory,
  filterWithSubCategory,
}) => {
  const [items, setItems] = useState([]);
  const [categoriesItem, setCategoriesItem] = useState([]);
  const [category, setCategory] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const currentLanguageCode = Cookies.get("i18next");
  const { t } = useTranslation();
  useEffect(() => {
    if (data) {
      if (!category) {
        setCategory(categories?.[0]);
      }
      setCategoriesItem(categories);
      setItems(data);
    }
  }, [categories, data]);
  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const getTime = (x) => {
    return " آخرین تغییر " + x?.split(" | ")[1] + " ساعت " + x?.split(" | ")[0];
  };
  const handleSelectTab = (status) => {
    filterWithSubCategory(status);
    setActiveTab(status);
  };
  const handleSelectCategory = (category) => {
    setCategory(category);
    setActiveTab(0);
    filterWithCategory(category?.id);
  };

  return (
    <>
      <Container>
        <ContainerCategories>
          {categoriesItem.map((categoryItem) => (
            <Category
              active={categoryItem?.id === category?.id}
              onClick={() =>
                categoryItem?.id !== category?.id &&
                handleSelectCategory(categoryItem)
              }
            >
              <ImgCategory src={categoryItem?.image} />
              {categoryItem?.title}
            </Category>
          ))}
        </ContainerCategories>
        <ContainerItems>
          <DivFilters>
            <Btnfilter
              onClick={() => handleSelectTab(0)}
              active={activeTab === 0}
            >
              همه
            </Btnfilter>
            {category?.childs?.map((subCategory) => (
              <Btnfilter
                onClick={() => handleSelectTab(subCategory?.id)}
                active={activeTab === subCategory?.id}
              >
                {subCategory?.title}
              </Btnfilter>
            ))}
          </DivFilters>

          {items.map((item) => (
            <Item>
              <Card src={currentLanguageCode === "fa" ? card : card_ltr} />
              {item?.last_detail &&
                item?.last_detail?.discount_type === "percentage" && (
                  <DiscountPriceLable>
                    {item?.last_detail?.discount_value + "٪"}
                  </DiscountPriceLable>
                )}
              {item?.last_detail &&
                item?.last_detail?.discount_type === "fixed" && (
                  <DiscountPriceLable>
                    {numberWithCommas(item?.last_detail?.discount_value) + " -"}
                  </DiscountPriceLable>
                )}

              <CoverImg>
                <Img loading="lazy" src={item?.image} />
              </CoverImg>
              <DetailItem>
                <Title empty={!item?.last_detail}>{item?.title}</Title>
                <Description empty={!item?.last_detail}>
                  {item?.last_detail
                    ? getTime(item?.last_detail?.jalali_register_at)
                    : "اطلاعات این محصول توسط شرکت شما ثبت نشده"}
                </Description>
                <Price empty={!item?.last_detail}>
                  {item?.last_detail ? (
                    <>
                      {numberWithCommas(item?.last_detail?.discount_price)}
                      {item?.last_detail?.discount_type !== "none" && (
                        <DiscountPrice>
                          {numberWithCommas(item?.last_detail?.price)}
                        </DiscountPrice>
                      )}

                      <Unit>{t("unit")}</Unit>
                    </>
                  ) : (
                    "بدون قیمت"
                  )}
                </Price>
              </DetailItem>
              <Weight>
                <UnitWeight>{item?.measure_unit}</UnitWeight>
                <DivProgressBar>
                  <VisibilitySensor>
                    {({ isVisible }) => {
                      const percentage = isVisible
                        ? item?.remaining_quantity_percentage
                        : 0;
                      return (
                        (item?.remaining_quantity_percentage < 10 && (
                          <CircularProgressbar
                            styles={buildStyles({
                              textSize: "2.2vw",
                              pathTransitionDuration: 2,
                              pathColor: `var(--red)`,
                              textColor: "black",
                              trailColor: "var(--trailColorProgressBar)",
                            })}
                            strokeWidth={11}
                            value={percentage}
                            text={item?.remaining_quantity}
                          />
                        )) ||
                        (item?.remaining_quantity_percentage >= 10 &&
                          item?.remaining_quantity_percentage < 80 && (
                            <CircularProgressbar
                              styles={buildStyles({
                                textSize: "2.2vw",
                                pathTransitionDuration: 2,
                                pathColor: `var(--orange)`,
                                textColor: "black",
                                trailColor: "var(--trailColorProgressBar)",
                              })}
                              strokeWidth={11}
                              value={percentage}
                              text={item?.remaining_quantity}
                            />
                          )) ||
                        (item?.remaining_quantity_percentage >= 80 && (
                          <CircularProgressbar
                            styles={buildStyles({
                              textSize: "2.2vw",
                              pathTransitionDuration: 2,
                              pathColor: `var(--green)`,
                              textColor: "black",
                              trailColor: "var(--trailColorProgressBar)",
                            })}
                            strokeWidth={11}
                            value={percentage}
                            text={item?.remaining_quantity}
                          />
                        )) ||
                        (!item?.remaining_quantity_percentage && (
                          <CircularProgressbar
                            styles={buildStyles({
                              textSize: "2.5vw",
                              pathTransitionDuration: 2,
                              pathColor: `var(--red)`,
                              textColor: "black",
                              trailColor: "var(--red)",
                            })}
                            strokeWidth={11}
                            value={0}
                            text={"0"}
                          />
                        ))
                      );
                    }}
                  </VisibilitySensor>
                </DivProgressBar>
              </Weight>
              <Btn onClick={() => handleOpenModal("modalOperations", item)}>
                <Icon src={setting} />
              </Btn>
            </Item>
          ))}
        </ContainerItems>
      </Container>
    </>
  );
};

export default List;
