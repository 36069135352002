import {
  Container,
  DetailItem,
  Img,
  Header,
  DivIconHead,
  IconHead,
  TitleHead,
  Head,
  DivTime,
  CalendarTime,
  ClockTime,
  SeparatorTime,
  Body,
  DivDetail,
  Lable,
  DivDetailProduct,
  DivDetailText,
  DetailText,
  Text,
  DivText,
  ImgDefault,
  DivEndHead,
  StatusDiv,
  Table,
  Tr,
  Td,
  Th,
  Thead,
  ImgProductTable,
  CoverImgProductTable,
  BtnStatus,
  ImgStatus,
  StatusHead,
  DivTitleHead,
  DivColorStatus,
} from "./ElementTradeUnionsRequests";
import icon from "../../Images/headerIcons/tradeUnions.svg";
import invalidIcon from "../../Images/invalidIcon.svg";
import agreeIcon from "../../Images/agreeIcon.svg";
import denyIcon from "../../Images/denyIcon.svg";
import statusIcon from "../../Images/headerIcons/status.svg";

import defaultBackground from "../../Images/defaultBackground.svg";

import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
const List = ({ detailItem, handleOpenModal }) => {
  const [item, setItem] = useState(null);
  const currentLanguageCode = Cookies.get("i18next");
  const { t } = useTranslation();
  useEffect(() => {
    if (detailItem) {
      console.log(detailItem);
      setItem(detailItem);
    }
  }, [detailItem]);
  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const getTime = (date) => {
    date = date.split(" | ");
    return date[1];
  };
  const getCalendar = (date) => {
    date = date.split(" | ");
    return date[0];
  };

  return (
    <>
      <Container UnPaddingStart>
        <DetailItem>
          {!item ? (
            <ImgDefault src={defaultBackground} />
          ) : (
            <>
              <Header>
                <Head>
                  <DivIconHead>
                    <IconHead
                      isLogo={item?.branch?.trade_unit?.logo}
                      src={
                        item?.branch?.trade_unit?.logo
                          ? item?.branch?.trade_unit?.logo
                          : icon
                      }
                    />
                  </DivIconHead>
                  <DivTitleHead>
                    <TitleHead>{item?.branch?.trade_unit?.title}</TitleHead>
                    <StatusHead>
                      <DivColorStatus color={item?.status?.color} />
                      {item?.status?.title}
                    </StatusHead>
                  </DivTitleHead>
                </Head>
                <DivEndHead
                  status={
                    (item?.status === 0 && "PENDING") ||
                    (item?.status === 1 && "AGREE") ||
                    (item?.status === 2 && "DENY") ||
                    (item?.status === 3 && "INVALID")
                  }
                >
                  <BtnStatus
                    onClick={() => handleOpenModal("modalStatus", item)}
                  >
                    <ImgStatus src={statusIcon} />
                    عملیات وضعیت فاکتور
                  </BtnStatus>

                  {((item?.status === 1 && "AGREE") ||
                    (item?.status === 2 && "DENY") ||
                    (item?.status === 3 && "INVALID")) && (
                    <>
                      <StatusDiv
                        status={
                          (item?.status === 1 && "AGREE") ||
                          (item?.status === 2 && "DENY") ||
                          (item?.status === 3 && "INVALID")
                        }
                      >
                        <Img
                          src={
                            (item?.status === 1 && agreeIcon) ||
                            (item?.status === 2 && denyIcon) ||
                            (item?.status === 3 && invalidIcon)
                          }
                        />
                      </StatusDiv>
                      {(item?.status === 1 && "تایید شده") ||
                        (item?.status === 2 && "رد شده") ||
                        (item?.status === 3 && "منقضی شده")}
                    </>
                  )}

                  <DivTime
                    status={
                      (item?.status === 0 && "PENDING") ||
                      (item?.status === 1 && "AGREE") ||
                      (item?.status === 2 && "DENY") ||
                      (item?.status === 3 && "INVALID") ||
                      "PENDING"
                    }
                  >
                    <ClockTime>{getTime(item?.jalali_payment_date)}</ClockTime>
                    <SeparatorTime
                      status={
                        (item?.status === 0 && "PENDING") ||
                        (item?.status === 1 && "AGREE") ||
                        (item?.status === 2 && "DENY") ||
                        (item?.status === 3 && "INVALID")
                      }
                    />
                    <CalendarTime>
                      {getCalendar(item?.jalali_payment_date)}
                    </CalendarTime>
                  </DivTime>
                </DivEndHead>
              </Header>
              <Body>
                <DivDetail>
                  <DivDetailProduct>
                    <Table>
                      <Thead>
                        <Th>تصویر</Th>
                        <Th>عنوان محصول</Th>
                        <Th>مقدار</Th>
                        <Th>قیمت واحد</Th>
                        <Th>قیمت نهایی</Th>
                      </Thead>
                      {item?.shopping_cart_items.map((shoppingItem) => (
                        <Tr>
                          <Td>
                            <CoverImgProductTable>
                              <ImgProductTable
                                src={shoppingItem?.product?.image}
                              />
                            </CoverImgProductTable>
                          </Td>
                          <Td>{shoppingItem?.product?.title}</Td>
                          <Td>
                            {shoppingItem?.quantity +
                              " " +
                              shoppingItem?.product?.measure_unit}
                          </Td>
                          <Td>
                            {numberWithCommas(shoppingItem?.price) + " تومان"}
                          </Td>
                          <Td>
                            {numberWithCommas(
                              shoppingItem?.price * shoppingItem?.quantity
                            ) + " تومان"}
                          </Td>
                        </Tr>
                      ))}
                    </Table>
                  </DivDetailProduct>
                </DivDetail>
                <DivDetail>
                  <DivDetailProduct sum>
                    <DivText row disablePadding>
                      <Text>
                        <Lable disablePadding> نحوه پرداخت :</Lable>
                        {item?.payment_method === "online"
                          ? "پرداخت اعتباری"
                          : "پرداخت درب محل"}
                      </Text>
                      <Text>
                        <Lable disablePadding>جمع فاکتور :</Lable>
                        {numberWithCommas(item?.total_amount) + " تومان"}
                      </Text>
                    </DivText>
                  </DivDetailProduct>
                </DivDetail>

                <DivDetail>
                  <DivDetailProduct>
                    <DivDetailText>
                      <DetailText>
                        <DivText>
                          <Text>
                            <Lable> تحویل گیرنده :</Lable>
                            {item?.recipient_full_name}
                          </Text>
                          <Text>
                            <Lable>شماره تحویل گیرنده :</Lable>
                            {item?.recipient_phone}
                          </Text>
                          <Text>
                            <Lable> شعبه تحویل گیرنده :</Lable>
                            {item?.branch?.title}
                          </Text>
                        </DivText>
                      </DetailText>
                      <DetailText>
                        <DivText>
                          <Text>
                            <Lable> تاریخ تحویل :</Lable>
                            {item?.jalali_delivery_date}
                          </Text>
                          <Text>
                            <Lable>بازه زمانی تحویل :</Lable>
                            {item?.delivery_time?.title}
                          </Text>
                          <Text>
                            <Lable> آدرس :</Lable>
                            {item?.branch?.address}
                          </Text>
                        </DivText>
                      </DetailText>
                    </DivDetailText>
                  </DivDetailProduct>
                </DivDetail>
              </Body>
            </>
          )}
        </DetailItem>
      </Container>
    </>
  );
};

export default List;
